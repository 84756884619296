const buildUrl = (href: string, data: object, marker = '{}') => {
  if (!isMarkerPresent(href)) return href;
  let result = href;
  const startMarker = marker[0];
  const endMarker = marker[1];

  const regex = new RegExp(`${startMarker}(.*?)${endMarker}`, 'g');
  const matches = result.match(regex) || [];

  for (const match of matches) {
    const property = extractPropertyFromMarker(match, marker);
    if (property && data[property] !== undefined) {
      result = result.replace(match, data[property]);
    }
  }

  return result;
};

const isMarkerPresent = (text: string, marker = '{*}') => {
  const regex = new RegExp(marker);
  return regex.test(text);
};

const extractPropertyFromMarker = (text: string, marker: string) => {
  if (text === "" || text == undefined || text == null || marker.length < 2) {
    return null;
  }
  const startMarker = marker[0];
  const endMarker = marker[1];
  const regex = new RegExp(`${startMarker}(.*?)${endMarker}`, 'g');
  const match = text.match(regex);
  return match ? match[0].replace(startMarker, '').replace(endMarker, '') : null;
};

const buildQueryParams = (query: object): string => {
  if (query == undefined || query == null) return "";
  let params = new URLSearchParams("")

  for (const [key, value] of Object.entries(query)) {
    params.append(key, value);
  }
  return "?" + params.toString();
}
export { buildUrl, buildQueryParams }

